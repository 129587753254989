//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  // props: ['indexData', 'editState','showLabel'],
  props: {
    indexData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    editState: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: "editState",
    event: "editChange",
  },
  data() {
    return {
      submiting: false, // 添加时，提交中

      comfirming: false, // 提交中
    };
  },
  created() {},
  methods: {
    // 添加窗口
    // 单击大添加按钮
    topSubmit() {
      this.$emit("topSubmit");
    },
    toggleSubmit() {
      this.submiting = !this.submiting;
    },

    // 编辑窗口
    // 单击小编辑后，小取消
    editClick() {
      this.$emit("editChange", !this.editState);
      this.$emit("editClick");
    },
    // 单击小确定后
    comfirmClick() {
      this.$emit("comfirmClick");
    },
    toggleComfirm() {
      this.comfirming = !this.comfirming;
    },
  },
};
